import { createSlice } from '@reduxjs/toolkit';
import io from 'socket.io-client';

let socket = null;
let messageSound = null;

const cleanup = () => {
  if (socket) {
    socket.removeAllListeners();
    socket.close();
    socket = null;
  }
  if (messageSound) {
    messageSound.src = '';
    messageSound = null;
  }
};

const initialState = {
  messages: [],
  isConnected: false,
  error: null,
  typingUsers: {},
  socket,
  messageStatuses: {},
  unsentMessages: [],
};

export const chatSlice = createSlice({
  name: 'chat',
  initialState,
  reducers: {
    setSocket: (state, action) => {
      state.socket = action.payload;
    },
    setConnectionStatus: (state, action) => {
      state.isConnected = action.payload;
    },
    messageReceived: (state, action) => {
      const messageKey = `${action.payload.timestamp}-${action.payload.userId}-${action.payload.message}`;
      const isDuplicate = state.messages.some(
        (msg) => `${msg.timestamp}-${msg.userId}-${msg.message}` === messageKey
      );

      if (!isDuplicate) {
        if (state.messages.length > 1000) {
          state.messages = state.messages.slice(-1000);
        }
        console.log('received message', action.payload);

        // Ensure msgUID is included in the stored message
        state.messages.push(action.payload);

        const user = JSON.parse(localStorage.getItem('user'));
        if (action.payload.userId === user.id && action.payload.msgUID) {
          state.messageStatuses[action.payload.msgUID] = 'delivered';
        }
      }
    },
    messageAdded: (state, action) => {
      if (state.messages.length > 1000) {
        state.messages = state.messages.slice(-1000);
      }
      state.messages.push({
        ...action.payload,
        roomId: action.payload.roomId,
      });
    },
    setError: (state, action) => {
      state.error = action.payload;
    },
    setTypingUser: (state, action) => {
      const { userId, message } = action.payload;
      state.typingUsers[userId] = { userId, message };

      setTimeout(() => {
        if (state.typingUsers[userId]) {
          delete state.typingUsers[userId];
        }
      }, 5000);
    },
    removeTypingUser: (state, action) => {
      delete state.typingUsers[action.payload.userId];
    },
    resetMessages: (state) => {
      state.messages = [];
      state.typingUsers = {};
    },

    setMessagePending: (state, action) => {
      const { msgUID } = action.payload;
      state.messageStatuses[msgUID] = 'pending';
    },

    setMessageDelivered: (state, action) => {
      const { msgUID } = action.payload;
      state.messageStatuses[msgUID] = 'delivered';
    },

    setMessageFailed: (state, action) => {
      const { msgUID } = action.payload;
      state.messageStatuses[msgUID] = 'failed';
    },
    addUnsentMessage: (state, action) => {
      state.unsentMessages.push(action.payload);
    },

    removeUnsentMessage: (state, action) => {
      state.unsentMessages = state.unsentMessages.filter(
        (msg) => msg.message.msgUID !== action.payload.msgUID
      );
    },

    clearUnsentMessages: (state) => {
      state.unsentMessages = [];
    },
  },
});

export const {
  setSocket,
  setConnectionStatus,
  messageReceived,
  messageAdded,
  setError,
  setTypingUser,
  removeTypingUser,
  resetMessages,
  setMessagePending,
  setMessageDelivered,
  setMessageFailed,
  addUnsentMessage,
  removeUnsentMessage,
  clearUnsentMessages,
} = chatSlice.actions;

const getMessageSound = () => {
  if (!messageSound) {
    messageSound = new Audio('/message_sent.mp3');
    messageSound.preload = 'none';
  }
  return messageSound;
};

export const initializeSocket = () => (dispatch, getState) => {
  const token = getState().token.value.token;
  const user = JSON.parse(localStorage.getItem('user'));

  cleanup();

  try {
    socket = io(import.meta.env.VITE_BASE_SOCKET, {
      autoConnect: true,
      transports: ['polling', 'websocket'],
      withCredentials: false,
      auth: { token },
      reconnectionAttempts: Infinity,
      reconnectionDelay: 1000,
      timeout: 10000,
    });

    const setupSocketListeners = () => {
      socket.on('connect', () => {
        console.log('Socket connected');
        dispatch(setConnectionStatus(true));
        socket.emit('newUser', user.id);

        const unsentMessages = getState().chat.unsentMessages;
        if (unsentMessages.length > 0) {
          console.log(`Retrying ${unsentMessages.length} unsent messages`);

          unsentMessages.forEach((messagePayload) => {
            dispatch(
              setMessagePending({ msgUID: messagePayload.message.msgUID })
            );

            socket.emit('sendMessage', messagePayload, (response) => {
              if (response?.error) {
                console.log('Message retry failed:', response.error);
                dispatch(
                  setMessageFailed({ msgUID: messagePayload.message.msgUID })
                );
              } else {
                dispatch(
                  removeUnsentMessage({ msgUID: messagePayload.message.msgUID })
                );
                getMessageSound()
                  .play()
                  .catch(() => {});
              }
            });
          });
        }
      });

      socket.on('user-typing', (data) => dispatch(setTypingUser(data)));
      socket.on('stop-typing', (data) => dispatch(removeTypingUser(data)));

      socket.on('error', (error) => {
        console.error('Socket error:', error);
        dispatch(setError(error.message));
      });

      socket.on('connect_error', (error) => {
        console.error('Connection error:', error);
        dispatch(setError(error.message));
      });

      socket.on('disconnect', () => {
        console.log('Socket disconnected');
        dispatch(setConnectionStatus(false));
      });

      // In the initializeSocket function, modify the receiveMessage handler:
      socket.on('receiveMessage', (message) => {
        console.log('Received message with UID:', message.msgUID); // Add this log
        dispatch(messageReceived(message));

        // If this is our own message coming back, mark it as delivered
        const user = JSON.parse(localStorage.getItem('user'));
        if (message.userId === user.id && message.msgUID) {
          console.log('Marking message as delivered:', message.msgUID); // Add this log
          dispatch(setMessageDelivered({ msgUID: message.msgUID }));
        }

        getMessageSound()
          .play()
          .catch(() => {});
      });
    };

    setupSocketListeners();

    window.addEventListener('beforeunload', cleanup);

    return () => {
      window.removeEventListener('beforeunload', cleanup);
      cleanup();
    };
  } catch (error) {
    console.error('Socket initialization failed:', error);
    dispatch(setError(error.message));
  }
};

export const sendMessage = (payload) => (dispatch) => {
  if (!socket?.connected) {
    console.error('Socket not connected');
    dispatch(setMessageFailed({ msgUID: payload.message.msgUID }));

    dispatch(addUnsentMessage(payload));
    return;
  }
  console.log('message sent: ', payload.message);
  dispatch(setMessagePending({ msgUID: payload.message.msgUID }));

  socket.emit('sendMessage', payload, (response) => {
    if (response?.error) {
      console.log('Message send failed:', response.error);
      dispatch(setMessageFailed({ msgUID: payload.message.msgUID }));
      dispatch(addUnsentMessage(payload));
    } else {
      dispatch(removeUnsentMessage({ msgUID: payload.message.msgUID }));
      getMessageSound()
        .play()
        .catch(() => {});
    }
  });
};

export const resendMessage = (messagePayload) => (dispatch) => {
  dispatch(setMessagePending({ msgUID: messagePayload.message.msgUID }));

  socket.emit('sendMessage', messagePayload, (response) => {
    if (response?.error) {
      console.log('Message resend failed:', response.error);
      dispatch(setMessageFailed({ msgUID: messagePayload.message.msgUID }));
    } else {
      getMessageSound()
        .play()
        .catch(() => {});
    }
  });
};

export default chatSlice.reducer;

export const selectSocket = (state) => state.chat.socket;
