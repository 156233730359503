import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

export const moreOptionsSlice = createApi({
  reducerPath: 'moreOptionsSlice',
  baseQuery: fetchBaseQuery({
    baseUrl: import.meta.env.VITE_BASE,
    prepareHeaders: (headers, { getState }) => {
      const token = getState().token.value.token;
      if (token) {
        headers.set('authorization', `Bearer ${token}`);
      }
      return headers;
    },
  }),
  tagTypes: ['Get'],
  endpoints: (builder) => ({
    tagTypes: ['Get'],

    getTermsById: builder.query({
      query: ({ roleId }) => ({
        url: `terms-of-use/role-free?roleId=${roleId}&category=terms`,
      }),
    }),
    getPrivacyById: builder.query({
      query: ({ roleId }) => ({
        url: `terms-of-use/role-free?roleId=${roleId}&category=privacy`,
      }),
    }),
    getHelpById: builder.query({
      query: ({ searchId }) => ({
        url: `help-topic?search=${searchId}`,
      }),
    }),
  }),
});

export const {
  useGetTermsByIdQuery,
  useGetPrivacyByIdQuery,
  useGetHelpByIdQuery,
} = moreOptionsSlice;
