import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

export const digiSlice = createApi({
  reducerPath: 'digiSlice',
  baseQuery: fetchBaseQuery({
    baseUrl: import.meta.env.VITE_BASE,
    prepareHeaders: (headers, { getState }) => {
      const token = getState().token.value.token;
      if (token) {
        headers.set('authorization', `Bearer ${token}`);
      }
      return headers;
    },
  }),
  tagTypes: ['GET_ALL', 'Digi_Review', 'GET_ONE'],
  endpoints: (builder) => ({
    // postComment: builder.mutation({
    //   query: (payload) => ({
    //     url: '/digiprenuers',
    //     method: 'POST',
    //     body: payload,
    //   }),
    //   // invalidatesTags: [{ type: 'Comment' }],
    // }),
    getDigi: builder.query({
      query: () => '/resource/digipreneurs-all',
      providesTags: ['GET_ALL'],
    }),
    getMyDigi: builder.query({
      query: () => '/resource/digipreneurs-sub',
      providesTags: ['GET_ALL'],
    }),
    getSchoolDigi: builder.query({
      query: (schoolId) => `/resource/digipreneurs?schoolId=${schoolId}`,
      providesTags: ['GET_ALL'],
    }),
    getOneDigi: builder.query({
      query: (id) => `/resource/digipreneurs/${id}`,
      providesTags: ['GET_ONE'],
    }),
    getDigiLevel: builder.query({
      query: ({ levelGrpId, subLevelId }) =>
        `/resource/digipreneurs/${levelGrpId}/courselevels/${subLevelId}`,
      providesTags: ['GET_LEVEL'],
    }),
    subFreeDigiCourse: builder.mutation({
      query: (payload) => ({
        url: 'digipreneurs/free-subscribe',
        method: 'POST',
        body: payload,
      }),
      invalidatesTags: ['Get_ALL', 'GET_ONE'],
    }),
    getDigiDays: builder.query({
      query: ({ groupId, dayId }) =>
        `/resource/digipreneurs/${groupId}/coursedays/${dayId}`,
      providesTags: ['GET_LEVEL'],
    }),
    getCourseAssessmentQuestion: builder.mutation({
      query: ({ digiId, quizId }) => ({
        url: `/course-assessments/user`,
        method: 'POST',
        body: {
          digipreneurId: digiId,
          quizId: quizId,
        },
      }),
    }),
    submitAssessmentQuestion: builder.mutation({
      query: (payload) => ({
        url: `/progress-trackers/digipreneurs/`,
        method: 'POST',
        body: payload,
      }),
    }),
    sendAssessmentProgress: builder.mutation({
      query: (payload) => ({
        url: `/progress-trackers/digipreneurs/`,
        method: 'POST',
        body: payload,
      }),
    }),
    getDigiReviews: builder.query({
      query: (id) => `course-comment/get-comments?digipreneurId=${id}`,
      providesTags: ['Digi_Review'],
    }),
    reviewDigi: builder.mutation({
      query: (payload) => ({
        url: '/course-comment/create-update-comment',
        method: 'POST',
        body: payload,
      }),
      invalidatesTags: ['Digi_Review'],
    }),
  }),
});

export const {
  useGetDigiQuery,
  useGetMyDigiQuery,
  useGetSchoolDigiQuery,
  useGetOneDigiQuery,
  useGetDigiLevelQuery,
  useGetDigiDaysQuery,
  useGetDigiReviewsQuery,
  useReviewDigiMutation,
  useSubFreeDigiCourseMutation,
  useGetCourseAssessmentQuestionMutation,
  useSubmitAssessmentQuestionMutation,
  useSendAssessmentProgressMutation,
} = digiSlice;
