import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

export const taskSlice = createApi({
  reducerPath: 'taskSlice',
  baseQuery: fetchBaseQuery({
    baseUrl: import.meta.env.VITE_BASE,
    prepareHeaders: (headers, { getState }) => {
      const token = getState().token.value.token;
      if (token) {
        headers.set('authorization', `Bearer ${token}`);
      }
      return headers;
    },
  }),
  tagTypes: ['Get', 'MockExamTask'],
  endpoints: (builder) => ({
    tagTypes: ['Get'],
    getStudyTask: builder.query({
      query: (id) => `resource/study/task/${id}`,
    }),
    getTasks: builder.query({
      query: ({ page = 1, limit = 10 } = {}) =>
        `progress-trackers/tasks-all?page=${page}&limit=${limit}`,
    }),
    getMissedTasks: builder.query({
      query: ({ page = 1, limit = 10 } = {}) =>
        `progress-trackers/tasks-missed?page=${page}&limit=${limit}`,
    }),
    getCompletedTasks: builder.query({
      query: ({ page = 1, limit = 10 } = {}) =>
        `progress-trackers/tasks-complete?page=${page}&limit=${limit}`,
    }),
    getUnCompletedTasks: builder.query({
      query: ({ page = 1, limit = 10 } = {}) =>
        `progress-trackers/tasks-uncompleted?page=${page}&limit=${limit}`,
    }),
    getSuperTasks: builder.query({
      query: ({ page = 1, limit = 10 } = {}) =>
        `progress-trackers/tasks-super-task?page=${page}&limit=${limit}`,
    }),
    getDigiTasks: builder.query({
      query: ({ page = 1, limit = 10 } = {}) =>
        `progress-trackers/digi-task?page=${page}&limit=${limit}`,
    }),
    getSchoolTasks: builder.query({
      query: ({ schoolId, page = 1, limit = 10 }) =>
        `progress-trackers/tasks-all?schoolId=${schoolId}&page=${page}&limit=${limit}`,
    }),
    getSchoolMissedTasks: builder.query({
      query: ({ schoolId, page = 1, limit = 10 }) =>
        `progress-trackers/tasks-missed?schoolId=${schoolId}&page=${page}&limit=${limit}`,
    }),
    getSchoolCompletedTasks: builder.query({
      query: ({ schoolId, page = 1, limit = 10 }) =>
        `progress-trackers/tasks-complete?schoolId=${schoolId}&page=${page}&limit=${limit}`,
    }),
    getSchoolUnCompletedTasks: builder.query({
      query: ({ schoolId, page = 1, limit = 10 }) =>
        `progress-trackers/tasks-uncompleted?schoolId=${schoolId}&page=${page}&limit=${limit}`,
    }),
    getSchoolSuperTasks: builder.query({
      query: ({ schoolId, page = 1, limit = 10 }) =>
        `progress-trackers/tasks-super-task?schoolId=${schoolId}&page=${page}&limit=${limit}`,
    }),
    getSingleTaskGrade: builder.query({
      query: (id) => `progress-trackers/tasks/${id}`,
    }),
    getSingleTask: builder.query({
      query: ({ id, schoolId }) =>
        `resource/study/task/${id}?schoolId=${schoolId}`,
    }),
    getDigiSingleTask: builder.query({
      query: (taskId) => `professional/task-one/${taskId}`,
    }),
    getMockExams: builder.query({
      query: (id) => `progress-trackers/mockexams/${id}`,
    }),
    submitTask: builder.mutation({
      query: (payload) => ({
        url: 'progress-trackers/',
        method: 'POST',
        body: payload,
      }),
    }),
    submitDigiTask: builder.mutation({
      query: (payload) => ({
        url: 'progress-trackers/professional-task',
        method: 'POST',
        body: payload,
      }),
    }),
    mockExamProgress: builder.query({
      query: () => 'progress-trackers/mockexams',
      providesTags: ['MockExamTask'],
    }),
    submitMockExamTask: builder.mutation({
      query: (payload) => ({
        url: 'progress-trackers/mockexams',
        method: 'POST',
        body: payload,
      }),
      invalidatesTags: [{ type: 'MockExamTask' }],
    }),
    studyTask: builder.query({
      query: ({ id }) => `/resource/study/task/${id}`,
    }),
    studyTaskSubmit: builder.mutation({
      query: (payload) => ({
        url: 'progress-trackers/topic-questions',
        method: 'POST',
        body: payload,
      }),
    }),

    getStudentProgress: builder.query({
      query: ({ taskId, learnerId }) =>
        `/tutor/get-task/${taskId}/${learnerId}`,
    }),
    addQuestionSBGS: builder.mutation({
      query: (payload) => ({
        url: 'sbsgs',
        method: 'POST',
        body: payload,
      }),
    }),
    getSuperTaskDetails: builder.query({
      query: (id) => `/sbsgs/tasks/${id}`,
    }),
  }),
});

export const {
  useGetStudyTaskQuery,
  useGetTasksQuery,
  useGetMissedTasksQuery,
  useGetCompletedTasksQuery,
  useGetUnCompletedTasksQuery,
  useGetSuperTasksQuery,
  useGetDigiTasksQuery,
  useGetSchoolTasksQuery,
  useGetSchoolMissedTasksQuery,
  useGetSchoolCompletedTasksQuery,
  useGetSchoolUnCompletedTasksQuery,
  useGetSchoolSuperTasksQuery,
  useLazyGetTasksQuery,
  useGetSingleTaskQuery,
  useGetDigiSingleTaskQuery,
  useGetSingleTaskGradeQuery,
  useSubmitTaskMutation,
  useSubmitDigiTaskMutation,
  useGetMockExamsQuery,
  useSubmitMockExamTaskMutation,
  useMockExamProgressQuery,
  useStudyTaskQuery,
  useStudyTaskSubmitMutation,
  useGetStudentProgressQuery,
  useAddQuestionSBGSMutation,
  useGetSuperTaskDetailsQuery,
} = taskSlice;
