import BasicButton from '../../components/BasicButton';
import { useNavigate } from 'react-router-dom';
import Button from '../../components/Button';
import GoogleSvg from '../../svg/Google';
import { useEffect, useState } from 'react';
// import DateComponent from '../../components/Date';
import ArrowBack from '../../svg/ArrowBackSmall';
import ArrowRight from '../../svg/ArrowRight';

import Info from '../../svg/Info';

import '../../styles/mobileView.scss';
import '../../styles/onboarding.scss';
import './index.scss';

// import PageFive from '../../svg/PageFive';

const MobileView = ({ handleSubmit, code, setCode }) => {
  const navigate = useNavigate();
  // const [code, setCode] = useState("");

  useEffect(() => {
    const adjustHeight = () => {
      const container = document.querySelector('.mobile');
      if (container) {
        container.style.height = window.innerHeight + 'px';
      }
    };

    // Adjust the height initially and listen for resize events
    adjustHeight();
    window.addEventListener('resize', adjustHeight);

    // Cleanup the event listener
    return () => {
      window.removeEventListener('resize', adjustHeight);
    };
  }, []);

  return (
    <div className="mobileView">
      <div className="">
        {/* <BasicButton onClick={() => navigate(-1)}>
          <ArrowBack />
        </BasicButton> */}
        <p
          className="topNav__skip"
          onClick={() => navigate('/professional-home')}
        >
          Skip
        </p>
        {/* <div className="signup-student__progress">
          <PageFive />
        </div> */}
      </div>

      <section className="scratch-code">
        <div className="mobileView__b">
          <p className="mobileView__p">Enter Scratch</p>
          <p className="mobileView__p">Code</p>
          <br />
          <p className="mobileView__d signup-student__title-sub">
            Kindly enter your 10-digit <br /> Scratch code.
          </p>
          <div className="signup-student__info">
            <Info />
            <p>If you do not have a scratch code, you can skip this page.</p>
          </div>
        </div>
        <main className="scratch-code__field">
          <div className="">
            <section className="">
              <article className="scratch-code__code">
                <ArrowRight />
                <input
                  className=""
                  onChange={(e) => setCode(e.target.value)}
                  onKeyPress={(e) => {
                    if (!/[0-9]/.test(e.key)) {
                      e.preventDefault();
                    }
                  }}
                  placeholder="8747683912"
                  type="text"
                  value={code}
                />
              </article>
              <br />
              <Button
                borderRadius="8px"
                onClick={() => handleSubmit(code)}
                type="submit"
              >
                Enter
              </Button>
            </section>
          </div>
        </main>
      </section>
    </div>
  );
};

export default MobileView;
