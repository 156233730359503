import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

export const counsellorSlice = createApi({
  reducerPath: 'counsellor',
  baseQuery: fetchBaseQuery({
    baseUrl: import.meta.env.VITE_BASE,
    prepareHeaders: (headers, { getState }) => {
      const token = getState().token.value.token;
      if (token) {
        headers.set('authorization', `Bearer ${token}`);
      }
      return headers;
    },
  }),
  tagTypes: ['Counsellor'],
  endpoints: (builder) => ({
    getCounsellorProfile: builder.query({
      query: () => ({
        url: '/counsellor/profile/fetch',
        method: 'GET',
      }),
      providesTags: ['Counsellor'],
    }),
    addBio: builder.mutation({
      query: (payload) => ({
        url: '/counsellor/add-bio',
        method: 'PUT',
        body: payload,
      }),
      invalidatesTags: ['Counsellor'],
    }),
    addCounsellorCredentials: builder.mutation({
      query: (payload) => ({
        url: '/auth/addCounsellorCredentials',
        method: 'POST',
        body: payload,
      }),
      invalidatesTags: ['Counsellor'],
    }),
    updateAccountType: builder.mutation({
      query: (payload) => ({
        url: '/auth/quickSetup-counsellor',
        method: 'POST',
        body: payload,
      }),
      invalidatesTags: ['Counsellor'],
    }),
    addCounsellorReferee: builder.mutation({
      query: (payload) => ({
        url: '/auth/addCounsellorReferee',
        method: 'POST',
        body: payload,
      }),
      invalidatesTags: ['Counsellor'],
    }),
    agreeTerms: builder.mutation({
      query: (payload) => ({
        url: '/counsellor/terms-of-use',
        method: 'PUT',
        body: payload,
      }),
      invalidatesTags: ['Counsellor'],
    }),
    addSchool: builder.mutation({
      query: (payload) => ({
        url: 'counsellor/add-school',
        method: 'POST',
        body: payload,
      }),
    }),
    counsellorRequest: builder.mutation({
      query: (payload) => ({
        url: '/counsellor/toggle-agreement-status',
        method: 'PATCH',
        body: payload,
      }),
    }),
    counsellorFee: builder.mutation({
      query: (payload) => ({
        url: 'counsellor/set-counsellor-fee',
        method: 'PATCH',
        body: payload,
      }),
      invalidatesTags: ['Counsellor'],
    }),
    counsellorSchool: builder.mutation({
      query: (payload) => ({
        url: 'counsellor/add-school',
        method: 'POST',
        body: payload,
      }),
      invalidatesTags: ['Counsellor'],
    }),
    counsellorRequest: builder.mutation({
      query: (payload) => ({
        url: '/counsellor/toggle-agreement-status',
        method: 'PATCH',
        body: payload,
      }),
    }),
  }),
});

export const {
  useGetCounsellorProfileQuery,
  useAddBioMutation,
  useAddCounsellorCredentialsMutation,
  useUpdateAccountTypeMutation,
  useAddCounsellorRefereeMutation,
  useAddSchoolMutation,
  useAgreeTermsMutation,

  useCounsellorFeeMutation,
  useCounsellorSchoolMutation,
  useCounsellorRequestMutation,
} = counsellorSlice;
