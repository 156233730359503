import './sentrySetup';
import ReactDOM from 'react-dom/client';
import { store } from './store';
import { Provider } from 'react-redux';
import { RouterProvider } from 'react-router-dom';
import { router } from './route';
import reportWebVitals from './reportWebVitals';
import SetUserToken from './service/SetUserToken';

import './index.scss';
import 'toastify-js/src/toastify.css';

import { registerSW } from 'virtual:pwa-register';

if ('serviceWorker' in navigator) {
  // && !/localhost/.test(window.location) && !/lvh.me/.test(window.location)) {
  // const updateSW = registerSW({
  //   onNeedRefresh() {
  //     successToast('An update is available!');
  //   },
  // });
  console.log('service worker running!!', '');
}
window.global = window;
ReactDOM.createRoot(document.getElementById('root')).render(
  // <React.StrictMode>
  <Provider store={store}>
    <SetUserToken>
      <RouterProvider router={router} />
    </SetUserToken>
  </Provider>
  // </React.StrictMode>,
);

reportWebVitals(console.log);
