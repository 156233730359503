import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

export const parentSlice = createApi({
  reducerPath: 'parentSlice',
  baseQuery: fetchBaseQuery({
    baseUrl: import.meta.env.VITE_BASE,
    prepareHeaders: (headers, { getState }) => {
      const token = getState().token.value.token;
      if (token) {
        headers.set('authorization', `Bearer ${token}`);
      }
      return headers;
    },
  }),
  tagTypes: ['Parent', 'POST', 'DELETE', 'PUT', 'Parent_profile'],
  endpoints: (builder) => ({
    getParent: builder.query({
      query: () => `guardian`,
      providesTags: ['Parent'],
    }),
    getParentProfile: builder.query({
      query: () => `guardian/get-guardian-profile`,
      providesTags: ['Parent_profile'],
    }),
    getRecommendedTutors: builder.query({
      query: ({ learnerSize, childId }) =>
        `guardian/recommended-tutor?learnerSize=${learnerSize}&childId=${childId}`,
    }),
    getRecommendedSubjects: builder.query({
      query: ({ learnerSize, childId }) =>
        `guardian/recommended-subjects?learnerSize=${learnerSize}&childId=${childId}`,
    }),
    getChildProfile: builder.query({
      query: ({ childId }) => `guardian/view-child-profile?childId=${childId}`,
    }),
    ringChild: builder.query({
      query: ({ childId }) => `guardian/alert-child?childId=${childId}`,
    }),

    removeChild: builder.mutation({
      query: ({ id }) => ({
        url: `guardian/remove-child/${id}`,
        method: 'DELETE',
      }),
    }),
    requestChild: builder.mutation({
      query: (payload) => ({
        url: 'guardian/request-child',
        method: 'POST',
        body: payload,
      }),
    }),
    acceptChild: builder.mutation({
      query: ({ id, payload }) => ({
        url: `guardian/child-accept/${id}`,
        method: 'PUT',
        body: payload,
      }),
    }),
  }),
});

export const {
  useGetParentQuery,
  useGetParentProfileQuery,
  useGetRecommendedTutorsQuery,
  useGetRecommendedSubjectsQuery,
  useRingChildQuery,
  useRemoveChildMutation,
  useAcceptChildMutation,
  useGetChildProfileQuery,
  useRequestChildMutation,
} = parentSlice;
