import './index.scss';

const OnboardingWrapper = ({ children, style }) => {
  return (
    <section className="onboarding" style={style}>
      {children}
    </section>
  );
};

export default OnboardingWrapper;
