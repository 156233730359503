import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

export const communitySlice = createApi({
  reducerPath: 'communitySlice',
  baseQuery: fetchBaseQuery({
    baseUrl: import.meta.env.VITE_BASE,
    prepareHeaders: (headers, { getState }) => {
      const token = getState().token.value.token;
      if (token) {
        headers.set('authorization', `Bearer ${token}`);
      }
      return headers;
    },
  }),
  tagTypes: [
    'Get',
    'GET_EVENT',
    'GET_COMMUNITIES',
    'GET_PROFESSIONAL_COMMUNITIES',
    'GET_PROFESSIONAL_EVENT',
  ],
  endpoints: (builder) => ({
    getCommunity: builder.query({
      query: () => `community/general`,
      providesTags: ['GET_COMMUNITIES'],
    }),
    getProfessionalCommunity: builder.query({
      query: () => `community/professional`,
      providesTags: ['GET_PROFESSIONAL_COMMUNITIES'],
    }),
    getSchoolCommunity: builder.query({
      query: (schoolId) => `community/general?schoolId=${schoolId}`,
      providesTags: ['GET_COMMUNITIES'],
    }),
    getEvents: builder.query({
      query: (params = {}) => {
        const {
          schoolId,
          page = 1,
          limit = 10,
          title,
          category,
          combine,
          childId,
        } = params;

        const queryParams = new URLSearchParams();

        if (schoolId) queryParams.append('schoolId', schoolId);
        if (page) queryParams.append('page', page);
        if (limit) queryParams.append('limit', limit);
        if (title) queryParams.append('title', title);
        if (category) queryParams.append('category', category);
        if (combine) queryParams.append('combine', combine);
        if (childId) queryParams.append('childId', childId);

        return `events/users?${queryParams.toString()}`;
      },
      providesTags: ['GET_EVENT'],
    }),
    getProfessionalEvents: builder.query({
      query: () => `events/professional`,
      providesTags: ['GET_PROFESSIONAL_EVENT'],
    }),
    // remove
    getSchoolEvents: builder.query({
      query: (schoolId) => `events/users?schoolId=${schoolId}`,
      providesTags: ['GET_EVENT'],
    }),
    likeEvent: builder.mutation({
      query: (id) => ({
        url: `events/${id}`,
        method: 'PATCH',
      }),
    }),
    toggleAttendEvent: builder.mutation({
      query: (payload) => ({
        url: `events/attend/${payload}`,
        method: 'PATCH',
        body: payload,
      }),
      invalidatesTags: [{ type: 'GET_EVENT' }],
    }),
  }),
});

export const {
  useGetEventsQuery,
  useGetCommunityQuery,
  useGetProfessionalCommunityQuery,
  useGetProfessionalEventsQuery,
  useGetSchoolCommunityQuery,
  useGetSchoolEventsQuery,
  useLikeEventMutation,
  useToggleAttendEventMutation,
} = communitySlice;
