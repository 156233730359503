import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

export const studentSlice = createApi({
  reducerPath: 'studentSlice',
  baseQuery: fetchBaseQuery({
    baseUrl: import.meta.env.VITE_BASE,
    prepareHeaders: (headers, { getState }) => {
      const token = getState().token.value.token;
      if (token) {
        headers.set('authorization', `Bearer ${token}`);
      }
      return headers;
    },
  }),
  tagTypes: ['Student', 'GET_STUDENT', 'School'],
  endpoints: (builder) => ({
    getStudent: builder.query({
      query: () => `learner`,
      providesTags: ['Student'],
    }),
    updateBio: builder.mutation({
      query: (payload) => ({
        url: 'learner/update-bio',
        method: 'PUT',
        body: payload,
      }),
      invalidatesTags: [{ type: 'Student' }],
    }),
    updateProfile: builder.mutation({
      query: (payload) => ({
        url: 'user/update-profile',
        method: 'PUT',
        body: payload,
      }),
      invalidatesTags: [{ type: 'Student' }],
    }),
    sendUpdateOtp: builder.mutation({
      query: (payload) => ({
        url: 'user/send-otp',
        method: 'POST',
        body: payload,
      }),
    }),
    updateEmailOrPhone: builder.mutation({
      query: (payload) => ({
        url: 'user/update-auth',
        method: 'PUT',
        body: payload,
      }),
      // invalidatesTags: [{ type: 'Student' }],
    }),
    updatePassword: builder.mutation({
      query: (payload) => ({
        url: 'auth/updatepassword',
        method: 'PUT',
        body: payload,
      }),
    }),
    updateAvatar: builder.mutation({
      query: (payload) => ({
        url: 'user/update-avatar',
        method: 'PUT',
        body: payload,
      }),
      invalidatesTags: [{ type: 'Student' }],
    }),
    addSchool: builder.mutation({
      query: (payload) => ({
        url: 'learner/add-school',
        method: 'POST',
        body: payload,
      }),
      invalidatesTags: [{ type: 'Student' }],
    }),
    removeSchool: builder.mutation({
      query: (payload) => ({
        url: 'learner/remove-school',
        method: 'PUT',
        body: payload,
      }),
      invalidatesTags: [{ type: 'Student' }],
    }),
    updateContent: builder.mutation({
      query: (payload) => ({
        url: 'learner/update-content',
        method: 'PUT',
        body: payload,
      }),
      invalidatesTags: [{ type: 'Student' }],
    }),
    getSchoolResources: builder.query({
      query: () => ({
        url: 'school/resources',
        providesTags: ['School'],
      }),
      invalidatesTags: [{ type: 'Student' }],
    }),
    verifySchool: builder.mutation({
      query: (payload) => ({
        url: 'learner/verify-school',
        method: 'POST',
        body: payload,
      }),
      invalidatesTags: [{ type: 'Student' }],
    }),
    resendOTP: builder.mutation({
      query: (payload) => ({
        url: 'user/resend-otp',
        method: 'PUT',
        body: payload,
      }),
    }),
  }),
});

export const {
  useGetStudentQuery,
  useUpdateBioMutation,
  useUpdateProfileMutation,
  useSendUpdateOtpMutation,
  useUpdateEmailOrPhoneMutation,
  useUpdatePasswordMutation,
  useUpdateAvatarMutation,
  useAddSchoolMutation,
  useRemoveSchoolMutation,
  useGetSchoolResourcesQuery,
  useUpdateContentMutation,
  useVerifySchoolMutation,
  useResendOTPMutation,
} = studentSlice;
