import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

export const sbsgSlice = createApi({
  reducerPath: 'sbsgSlice',
  baseQuery: fetchBaseQuery({
    baseUrl: import.meta.env.VITE_BASE,
    prepareHeaders: (headers, { getState }) => {
      const token = getState().token.value.token;
      if (token) {
        headers.set('authorization', `Bearer ${token}`);
      }
      return headers;
    },
  }),
  tagTypes: ['GET_SBSG'],
  endpoints: (builder) => ({
    sbsgPreview: builder.query({
      query: ({ taskId }) => `sbsgs/learners/tasks/${taskId}`,
      providesTags: ['GET_SBSG'],
    }),
    addSbsg: builder.mutation({
      query: ({ payload }) => ({
        url: `sbsgs/`,
        method: 'POST',
        body: payload,
      }),
      invalidatesTags: ['GET_SBSG'],
    }),
    editSbsg: builder.mutation({
      query: ({ payload, sbsgId }) => ({
        url: `sbsgs/${sbsgId}`,
        method: 'PUT',
        body: payload,
      }),
      invalidatesTags: ['GET_SBSG'],
    }),
    uploadFile: builder.mutation({
      query: (payload) => ({
        url: 'upload/',
        method: 'POST',
        body: payload,
      }),
    }),
  }),
});

export const {
  useSbsgPreviewQuery,
  useAddSbsgMutation,
  useEditSbsgMutation,
  useUploadFileMutation,
} = sbsgSlice;
