import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

export const counsellorAvailabilitySlice = createApi({
  reducerPath: 'counsellorAvailability',
  baseQuery: fetchBaseQuery({
    baseUrl: import.meta.env.VITE_BASE,
    prepareHeaders: (headers, { getState }) => {
      const token = getState().token.value.token;
      if (token) {
        headers.set('authorization', `Bearer ${token}`);
      }
      return headers;
    },
  }),
  tagTypes: ['Counsellor_available', 'Counsellees', 'Counsellor_dates'],
  endpoints: (builder) => ({
    getCounsellorAvailability: builder.query({
      query: (statusParam) => {
        const queryString = statusParam
          ? `?statusParam=${encodeURIComponent(statusParam)}`
          : '';
        return `counsellor/requests-queue${queryString}`;
      },
      providesTags: ['Counsellor_available'],
    }),
    getAvailableDates: builder.query({
      query: () => 'counsellor/my-availability',
      providesTags: ['Counsellor_dates'],
    }),
    getCounsellees: builder.query({
      query: (statusParam) => ({
        url: `counsellor/my-counsellees?statusParam=${statusParam}`,
      }),
      providesTags: ['Counsellees'],
    }),
    setCounsellorAvailability: builder.mutation({
      query: (payload) => ({
        url: 'counsellor/counsellor-create',
        method: 'POST',
        body: payload,
      }),
      invalidatesTags: ['Counsellor_dates'],
    }),
    deleteCounsellorAvailability: builder.mutation({
      query: (payload) => ({
        url: `counsellor/remove-availability-record`,
        method: 'DELETE',
        body: payload,
      }),
      invalidatesTags: ['Counsellor_dates'],
    }),

    // ---
    toggleAgreementStatus: builder.mutation({
      query: (payload) => ({
        url: 'counsellor/toggle-agreement-status',
        method: 'PATCH',
        body: payload,
      }),
      invalidatesTags: ['Counsellor_available'],
    }),
    toggleBookingStatus: builder.mutation({
      query: ({ id, statusParam }) => ({
        url: `counsellor/toggle-booking-status/${id}`,
        method: 'PATCH',
        body: { statusParam },
      }),
      invalidatesTags: ['Counsellor_available'],
    }),
    // ---
    startConversation: builder.mutation({
      query: (payload) => ({
        url: '/counsellor/start-conversation',
        method: 'POST',
        body: payload,
      }),
      invalidatesTags: ['Counsellor_available', 'Counsellor_dates'],
    }),
  }),
});

export const {
  useGetCounsellorAvailabilityQuery,
  useGetCounselleesQuery,
  useSetCounsellorAvailabilityMutation,
  useGetAvailableDatesQuery,
  useDeleteCounsellorAvailabilityMutation,
  useToggleAgreementStatusMutation,
  useToggleBookingStatusMutation,
  useStartConversationMutation,
} = counsellorAvailabilitySlice;
