export const tokenMiddleware = (storeAPI) => (next) => (action) => {
  const result = next(action);

  setTimeout(() => {
    if (typeof window !== 'undefined') {
      const urlParams = new URLSearchParams(window.location.search);
      const token = urlParams.get('token');

      if (token) {
        // Save token to localStorage
        localStorage.setItem('token', token);

        // Update URL without the token parameter
        urlParams.delete('token');
        const newUrl =
          window.location.pathname +
          (urlParams.toString() ? `?${urlParams.toString()}` : '') +
          window.location.hash;

        window.history.replaceState({}, document.title, newUrl);
      }
    }
  }, 0);

  return result;
};
