import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

export const resourceSlice = createApi({
  reducerPath: 'resourceSlice',
  baseQuery: fetchBaseQuery({
    baseUrl: import.meta.env.VITE_BASE,
    prepareHeaders: (headers, { getState }) => {
      const token = getState().token.value.token;
      if (token) {
        headers.set('authorization', `Bearer ${token}`);
      }
      return headers;
    },
  }),
  tagTypes: ['GET', 'GET_LIKES', 'GET_PODCAST'],
  endpoints: (builder) => ({
    tagTypes: ['Get', 'Subject'],
    subjectData: builder.query({
      query: () => `/resource/study/subject`,
      providesTags: ['Subject'],
    }),
    schoolSubjectData: builder.query({
      query: (schoolId) => `/resource/study/subject?schoolId=${schoolId}`,
      providesTags: ['Subject'],
    }),
    singleSubject: builder.query({
      query: (id) => `/resource/study/subject/${id}`,
      providesTags: ['Subject'],
    }),
    topicData: builder.query({
      query: (id) => `/resource/study/topic/${id}`,
    }),
    fetchSubjectTopics: builder.query({
      query: ({ id, page = 1, limit = 7, schoolId = '', search = '' }) => {
        const params = new URLSearchParams({
          page,
          limit,
          schoolId,
          search,
        }).toString();
        return `/resource/study/topic-match/${id}?${params}`;
      },
    }),
    videoData: builder.query({
      query: () => '/resource/video',
    }),
    schoolVideoData: builder.query({
      query: (schoolId) => `/resource/video?schoolId=${schoolId}`,
    }),
    singlePodcastVideo: builder.query({
      query: ({ id, type = 'podcast' }) =>
        `/resource/podcast-video/${id}?type=${type}`,
      providesTags: ['Get'],
    }),
    podcastData: builder.query({
      query: ({ schoolId, type = 'podcast', search }) => {
        let queryParams = new URLSearchParams();
        if (schoolId) queryParams.append('schoolId', schoolId);
        if (type) queryParams.append('type', type);
        if (search && typeof search === 'string' && search.trim() !== '')
          queryParams.append('search', search);

        return `/resource/all-podcast-video?${queryParams.toString()}`;
      },
      providesTags: ['GET_PODCAST'],
    }),
    getSubjectPodcast: builder.query({
      query: ({ schoolId, type = 'podcast', search }) => {
        let queryParams = new URLSearchParams();
        if (schoolId) queryParams.append('schoolId', schoolId);
        if (type) queryParams.append('type', type);
        if (search && typeof search === 'string' && search.trim() !== '')
          queryParams.append('search', search);

        return `/resource/podcast-video-subject?${queryParams.toString()}`;
      },
      providesTags: ['GET_PODCAST'],
    }),
    getRecommendedPodcast: builder.query({
      query: ({ schoolId, type = 'podcast', search }) => {
        let queryParams = new URLSearchParams();
        if (schoolId) queryParams.append('schoolId', schoolId);
        if (type) queryParams.append('type', type);
        if (search && typeof search === 'string' && search.trim() !== '')
          queryParams.append('search', search);

        return `/resource/podcast-video-recommended?${queryParams.toString()}`;
      },
      providesTags: ['GET_PODCAST'],
    }),
    getRecentPodcast: builder.query({
      query: ({ schoolId, type = 'podcast', search }) => {
        let queryParams = new URLSearchParams();
        if (type) queryParams.append('type', type);
        if (schoolId) queryParams.append('schoolId', schoolId);
        if (search && typeof search === 'string' && search.trim() !== '')
          queryParams.append('search', search);

        return `/resource/podcast-video-recent?${queryParams.toString()}`;
      },
      providesTags: ['GET_PODCAST'],
    }),
    getWatchTodayPodcast: builder.query({
      query: ({ schoolId, type = 'podcast', search }) => {
        let queryParams = new URLSearchParams();
        if (schoolId) queryParams.append('schoolId', schoolId);
        if (type) queryParams.append('type', type);
        if (search && typeof search === 'string' && search.trim() !== '')
          queryParams.append('search', search);

        return `/resource/podcast-video-watch-today?${queryParams.toString()}`;
      },
      providesTags: ['GET_PODCAST'],
    }),
    getMostWatchPodcast: builder.query({
      query: ({ schoolId, type = 'podcast', search }) => {
        let queryParams = new URLSearchParams();
        if (schoolId) queryParams.append('schoolId', schoolId);
        if (type) queryParams.append('type', type);
        if (search && typeof search === 'string' && search.trim() !== '')
          queryParams.append('search', search);

        return `/resource/podcast-video-most-watch?${queryParams.toString()}`;
      },
      providesTags: ['GET_PODCAST'],
    }),
    schoolPodcastData: builder.query({
      query: ({ schoolId, type = 'podcast', search }) => {
        let queryParams = new URLSearchParams();
        queryParams.append('schoolId', schoolId);
        if (type) queryParams.append('type', type);
        if (search && typeof search === 'string' && search.trim() !== '')
          queryParams.append('search', search);

        return `/resource/all-podcast-video?${queryParams.toString()}`;
      },
      providesTags: ['GET_PODCAST'],
    }),
    singlePodcastData: builder.query({
      query: ({ id, type = 'podcast' }) => {
        let queryParams = new URLSearchParams();
        if (type) queryParams.append('type', type);

        return `/resource/all-podcast-video/${id}?${queryParams.toString()}`;
      },
    }),
    singleSubjectData: builder.query({
      query: ({ id, type = 'podcast', search }) => {
        let queryParams = new URLSearchParams();
        if (type) queryParams.append('type', type);
        if (search && typeof search === 'string' && search.trim() !== '')
          queryParams.append('search', search);

        return `/resource/podcast-video/subjects/${id}?${queryParams.toString()}`;
      },
    }),
    gameData: builder.query({
      query: () => 'resource/games-all/',
      providesTags: ['GET_GAME'],
    }),
    schoolGameData: builder.query({
      query: (schoolId) => ` /resource/games-all?schoolId=${schoolId}`,
      providesTags: ['GET_GAME'],
    }),
    gameSingle: builder.query({
      query: (id) => `/resource/games/${id}`,
      providesTags: ['GET_SINGLE_GAME'],
    }),
    likeItem: builder.mutation({
      query: (payload) => ({
        url: '/likes',
        method: 'POST',
        body: payload,
      }),
      invalidatesTags: ['GET_LIKES'],
    }),
    getSingleLikes: builder.query({
      query: (id) => `likes/${id}`,
      providesTags: ['GET_LIKES'],
    }),
    getStudyTask: builder.query({
      query: (id) => `resource/study/task/${id}`,
    }),
    getStudyStore: builder.query({
      query: (id) => `resource/study/store/${id}`,
    }),
    updateEdu: builder.mutation({
      query: (payload) => ({
        url: 'learner/update/educations-info',
        method: 'PUT',
        body: payload,
      }),
      invalidatesTags: [{ type: 'Subject' }],
    }),
    updateSchoolEdu: builder.mutation({
      query: (payload) => ({
        url: 'learner/edu-school',
        method: 'PUT',
        body: payload,
      }),
      invalidatesTags: [{ type: 'Subject' }],
    }),
    getStudyVideo: builder.query({
      query: ({ topicId, videoId }) => `resource/study/${topicId}/${videoId}`,
    }),
    getVideoSubject: builder.query({
      query: (subjectId) => `resource/video/subjects/${subjectId}`,
    }),
    getPodcastSubject: builder.query({
      query: (subjectId) => `resource/podcast/subjects/${subjectId}`,
    }),
    updateGamePlayed: builder.mutation({
      query: (payload) => ({
        url: 'resource/game-played',
        method: 'POST',
        body: payload,
      }),
      invalidatesTags: [{ type: 'GET_GAME' }, { type: 'GET_SINGLE_GAME' }],
    }),
  }),
});

export const {
  useTopicDataQuery,
  useFetchSubjectTopicsQuery,
  useSubjectDataQuery,
  useSchoolSubjectDataQuery,
  useLazySubjectDataQuery,
  useVideoDataQuery,
  useSchoolVideoDataQuery,
  useLazyVideoDataQuery,
  usePodcastDataQuery,
  useSchoolPodcastDataQuery,
  useLazyPodcastDataQuery,
  useGameDataQuery,
  useSchoolGameDataQuery,
  useLazyGameDataQuery,
  useLikeItemMutation,
  useGetStudyTaskQuery,
  useGetStudyStoreQuery,
  useUpdateEduMutation,
  useUpdateSchoolEduMutation,
  useSingleSubjectQuery,
  useSingleSubjectDataQuery,
  useGetStudyVideoQuery,
  useSinglePodcastVideoQuery,
  useGetLikesQuery,
  useGetSingleLikesQuery,
  useGameSingleQuery,
  useGetVideoSubjectQuery,
  useLazyGetVideoSubjectQuery,
  useGetPodcastSubjectQuery,
  useSinglePodcastDataQuery,
  useLazySinglePodcastDataQuery,
  useUpdateGamePlayedMutation,
  useGetRecommendedPodcastQuery,
  useGetRecentPodcastQuery,
  useGetWatchTodayPodcastQuery,
  useGetMostWatchPodcastQuery,
  useGetSubjectPodcastQuery,
} = resourceSlice;
