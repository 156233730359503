import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { getToken } from '../store/slice/tokenSlice';
import StandardErrorBoundary from '../components/ErrorBoundary';
import { userTypeIds } from '../utility/userTypesId';

const SetUserToken = ({ children }) => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getToken());

    const storedToken = localStorage.getItem('token');
    const storedUser = localStorage.getItem('user');

    if (storedToken && storedUser) {
      // console.log('stored token', storedToken);
      try {
        const parsedUser = JSON.parse(storedUser);
        const currentPath = window.location.pathname;

        // console.log('user role ID', parsedUser.roleId);

        if (currentPath === '/' || currentPath === '/login') {
          let redirectTo = '';
          const userType = userTypeIds[parsedUser.roleId];
          switch (userType) {
            case 'tutor':
              redirectTo = '/tutor-home';
              break;
            case 'guardian':
              redirectTo = '/parent-home';
              break;
            case 'professional':
              redirectTo = '/professional-home';
              break;
            case 'counsellor':
              redirectTo = '/counsellor-home';
              break;
            case 'learner':
              redirectTo = '/home';
              break;
          }

          window.location.href = redirectTo;
        }
      } catch (error) {
        console.error('Error parsing user object:', error);
      }
    }
  }, [dispatch]);

  return <StandardErrorBoundary>{children}</StandardErrorBoundary>;
};

export default SetUserToken;
